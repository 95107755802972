import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
  StartupsTableContext,
} from './StartupsTableContext';
import { useGetRows } from './useGetRows';

import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import { CustomersField } from 'components/startupsPage/CustomersField';
import { useContext, useMemo, useState } from 'react';

import { useRightTableActions } from 'components/base/useRightTableActions';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { MoveStartupActions } from '../MoveStartupActions';
import { MemoizedNameColumn } from './NameColumn';
import { NewStartupEmptyRowGrid } from './NewStartupEmptyRowGrid';
import { LonglistSettings } from './Settings';
import { StartupCorporateCustomer } from './useGetColumns';

const useGetColumns = ({
  isPublicPage,
  isDiscovery,
  hasCustomers,
  onRemoveStartup,
  onAddToListClick,
}: {
  isPublicPage: boolean;
  isDiscovery: boolean;
  hasCustomers: boolean;
  onRemoveStartup?: (startup: DecoratedStartup) => void;
  onAddToListClick: (startup: DecoratedStartup) => void;
}) => {
  const { row, getActionsColumn, pinnedRightColumn } = useRightTableActions();

  const isEditable = !isDiscovery && !isPublicPage;

  const columns = useMemo(() => {
    const localCols = [
      {
        field: 'name',
        headerName: 'Position, company',
        minWidth: 290,
        renderCell: (
          params: GridRenderCellParams<string, DecoratedStartup>,
        ) => <MemoizedNameColumn {...params.row} isLongList={false} />,
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
      },
      {
        field: 'short_description',
        headerName: 'Description',
        minWidth: 350,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<string, DecoratedStartup>,
        ) => {
          const value = params.value || '-';

          return (
            <ExpandedGridCell
              value={value}
              multiLineElipsis={true}
              numberOfLines={2}
            />
          );
        },
        disableColumnMenu: true,
        sortable: false,
      },
      isEditable
        ? getActionsColumn({
            minWidth: 170,
            renderCellNode: (
              params: GridCellParams<unknown, DecoratedStartup>,
            ) => (
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                <BaseAddStartupToListMenu
                  selectedStartups={[params.row]}
                  onCreate={() => {
                    onAddToListClick(params.row);
                  }}
                  analyticsSource='STARTUP_LIST'
                />
                <MoveStartupActions
                  id={params.id}
                  startup={params.row}
                  onRemoveStartup={onRemoveStartup}
                />
              </Stack>
            ),
          })
        : null,
    ] as GridColumns<DecoratedStartup>;

    if (hasCustomers) {
      localCols.splice(1, 0, {
        field: 'startup_corporate_customers',
        headerName: 'Customers',
        minWidth: 180,
        align: 'center',
        renderCell: (
          params: GridRenderCellParams<
            StartupCorporateCustomer[],
            DecoratedStartup
          >,
        ) => {
          if (!params.value?.length) return '-';

          return (
            <CustomersField
              referencedCustomers={params.value.map(
                pv => pv.corporate_customer,
              )}
              keyHelper={params.row.domain as string}
            />
          );
        },
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
      });
    }

    return localCols.filter(Boolean);
  }, [
    getActionsColumn,
    hasCustomers,
    isEditable,
    onAddToListClick,
    onRemoveStartup,
  ]);

  return { columns, row, pinnedRightColumn };
};

export type FooterProps = {
  items: CategorizedStartupForMatrix[];
  isDiscovery: boolean;
  isPublicPage: boolean;
  shortlist: CategorizedStartupForMatrix[];
  onRemoveStartup?: (startup: DecoratedStartup) => void;
};

export const NewStartupRowToggleButton = ({
  shouldShowAddStartupRow,
  order,
}: {
  shouldShowAddStartupRow: boolean;
  order: number;
}) => {
  const [rowsForAddNew, setRowsForAddNew] = useState<DecoratedStartup[]>([]);

  const newStartupRow = [
    {
      id: -1,
      domain: '',
      name: '',
      startup_corporate_customers: [],
      categorized_startup_id: -1,
      voters: [],
      is_shortlisted: false,
      is_in_matrix: true,
      custom_dimensions_json_v1: {},
      is_recommended: false,
      all_startups_table_score: 0,
      matrix_table_score: 0,
      order,
    },
  ];

  return (
    <>
      {rowsForAddNew.length > 0 && (
        <NewStartupEmptyRowGrid
          rows={rowsForAddNew}
          onRemoveStartupRow={() => setRowsForAddNew([])}
        />
      )}

      {shouldShowAddStartupRow && (
        <Stack alignItems='flex-start'>
          <Button
            variant='text'
            onClick={() => setRowsForAddNew(newStartupRow)}
            disabled={rowsForAddNew.length > 0}
            startIcon={<Add />}
            color='secondaryCTA'
            sx={{
              margin: 0,
              width: '100%',
              borderRadius: '0 !important',
              justifyContent: 'flex-start',
              padding: 2,
              paddingLeft: 2.75,
              borderTop: ({ palette }) => palette.grey[300] + `0.5px solid`,
              fontWeight: 'normal',
            }}
          >
            ADD NEW
          </Button>
        </Stack>
      )}
    </>
  );
};
const Footer = ({
  items,
  isDiscovery,
  isPublicPage,
  shortlist,
  onRemoveStartup,
}: FooterProps) => {
  const [isExpanded, setIsExpanded] = useState(shortlist.length === 0);
  const { palette } = useTheme();
  const { currentStartupList } = useContext(StartupsTableContext)!;
  const { isSharedPage } = useSharedPagesContext();

  const hasCustomers = useMemo(
    () => items.some(cs => cs.startup.startup_corporate_customers?.length),
    [items],
  );

  const { columns, row, pinnedRightColumn } = useGetColumns({
    isPublicPage: !!isPublicPage,
    isDiscovery,
    hasCustomers,
    onRemoveStartup,
    onAddToListClick: () => {},
  });

  const rows = useGetRows({ categorizedStartups: items, filterMatrix: false });

  const shouldShowAddStartupRow =
    currentStartupList.source !== 'favourites' &&
    !currentStartupList.is_readonly &&
    !isPublicPage;

  return (
    <Stack>
      <NewStartupRowToggleButton
        shouldShowAddStartupRow={shouldShowAddStartupRow}
        order={useGetRows({ categorizedStartups: shortlist }).length + 1}
      />
      {rows.length > 0 && (
        <>
          <Accordion
            sx={{ padding: 0 }}
            expanded={isExpanded}
            onChange={(_, expanded) => setIsExpanded(expanded)}
            TransitionProps={{ unmountOnExit: false }}
            disableGutters
          >
            <AccordionSummary
              sx={{
                margin: '0 !important',
                padding: '0 !important',
                minHeight: 'auto !important',
                background: palette.grey[200],
                '&:hover': { background: palette.grey[300] },
                '& .MuiAccordionSummary-content': {
                  padding: '0 !important',
                  margin: '0 !important',
                },
              }}
              aria-controls='panel-content'
              id='panel'
            >
              <Stack
                direction='row'
                alignItems='center'
                padding={[1, 2]}
                gap={1}
              >
                <ExpandMore
                  sx={{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
                <Typography>Longlist ({rows.length})</Typography>
              </Stack>
              {!isDiscovery && isExpanded && !isSharedPage && (
                <LonglistSettings
                  currentStartupListId={currentStartupList.id}
                />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <DataGridPro
                initialState={{
                  pinnedColumns: {
                    left: ['name'],
                    right: pinnedRightColumn,
                  },
                }}
                rows={rows}
                // eslint-disable-next-line
                columns={columns}
                autoHeight
                hideFooterRowCount
                hideFooterPagination
                components={{ Footer: () => null }}
                componentsProps={{ row }}
                isRowSelectable={() => false}
                rowHeight={50}
                headerHeight={0}
                sx={{
                  '& .MuiDataGrid-row': { height: '50px !important' },
                  '& .MuiDataGrid-columnHeaders': {
                    display: 'none',
                  },
                }}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export { Footer };
