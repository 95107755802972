import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import PersonDetailsDrawer from 'components/engagement/people/details/DetailsDrawer';
import ScoutStartupsErrorBoundary from 'components/search/ScoutStartupsErrorBoundryComponent';
import { Props as ProjectsPageProps } from 'contexts/ProjectScopeContext';
import { CategoryDetailsPageProps } from 'pages/CategoryDetailsPage';
import { ProjectStageChangeDialog } from 'pages/dashboard/scoping/ProjectStageChangeDialog';
import { WithProjectFiltersFromUrlProps } from 'pages/dashboard/scoping/withProjectFiltersFromUrl';
import OutboundRequestsPage from 'pages/startupCRM/OutboundRequestsPage';
import PitchInboxPage from 'pages/startupCRM/PitchInboxPage';
import StartupsLayout from 'pages/startupCRM/StartupsLayout';
import StartupsPage from 'pages/startupCRM/StartupsPage';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { Loadable } from './Loadable';
import { PATH_NAME, PATH_PARAMS, PATH_ROOT, pathForRoutes } from './paths';

const DeprecatedRoute = ({ newPath }: { newPath: string }) => {
  captureAnalyticsEvent('Deprecated Path Visited', {
    path: window.location.pathname,
  });

  return <Navigate to={newPath} />;
};

const Home = Loadable(lazy(() => import('pages/HomePage')));

const LibraryLayout = Loadable(lazy(() => import('layouts/LibraryLayout')));

const ListDetailsPage = Loadable(
  lazy(() => import('pages/dashboard/ListDetailsPage')),
);
const CategoryDetailsPage = Loadable<CategoryDetailsPageProps>(
  lazy(() => import('pages/CategoryDetailsPage')),
);
const ScoutStartupsPage = Loadable(
  lazy(() => import('pages/dashboard/ScoutStartupsPage')),
);

// Engagement
// TODO: Create brand new request form page + new sourcing order management

const SourcingOrderPage = Loadable(
  lazy(() => import('pages/request-form/SourcingOrderPage')),
);

const ProjectLeadsListPage = Loadable(
  lazy(() => import('pages/implementations/ProjectLeadsListPage')),
);

const InboxDetailsPage = Loadable(
  lazy(() => import('pages/engagement/InboxDetailsPage')),
);

const PeopleBusinessUnitPage = Loadable(
  lazy(() => import('pages/engagement/PeopleBusinessUnitPage')),
);

const OrganizationPortalBuilderPage = Loadable(
  lazy(() => import('pages/organizationPortal/OrganizationPortalBuilderPage')),
);

const ActivitiesPage = Loadable(
  lazy(() => import('pages/engagement/ActivitiesPage')),
);
const LeadGenGPTPage = Loadable(
  lazy(() => import('pages/engagement/LeadGenCopilotPage')),
);

// Projects

const ProjectDetailsPage = Loadable<ProjectsPageProps>(
  lazy(() => import('pages/dashboard/ProjectDetailsPage')),
);
const ProjectLeadDetailsPage = Loadable(
  lazy(() => import('pages/dashboard/ProjectLeadDetailsPage')),
);

const PoCsAndAdoptionsPage = Loadable<WithProjectFiltersFromUrlProps>(
  lazy(() => import('pages/dashboard/ProjectsContentPage')),
);

const AnalyticsPage = Loadable(
  lazy(() => import('pages/analytics/AnalyticsPage')),
);

// Settings
const OrganizationSettingsPage = Loadable(
  lazy(() => import('pages/settings/OrganizationSettingsPage')),
);

const UsersPage = Loadable(lazy(() => import('pages/settings/UsersPage')));

const TeamsPage = Loadable(lazy(() => import('pages/settings/TeamsPage')));

const SettingsLayoutPage = Loadable(
  lazy(() => import('pages/settings/SettingsLayoutPage')),
);

const INTERNAL_ROUTES: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: PATH_NAME.projectLeads, element: <ProjectLeadsListPage /> },
  // This has to be here in cases where user is not logged in.
  // If this is not here, the router will go to 404 page
  {
    path: '/people',
    element: <DeprecatedRoute newPath={PATH_ROOT.stakeholders.root} />,
  },
  {
    path: `${PATH_NAME.personProfile}/:${PATH_PARAMS.personId}`,
    element: <PersonDetailsDrawer />,
  },
  {
    path: PATH_ROOT.changeProjectStage.dialog(
      `:${PATH_PARAMS.projectId}`,
      `:${PATH_PARAMS.currentStage}` as unknown as EnumTableProjectStagesEnum,
      `:${PATH_PARAMS.nextStage}` as unknown as EnumTableProjectStagesEnum,
    ),
    element: <ProjectStageChangeDialog />,
  },
  {
    path: pathForRoutes(PATH_ROOT.stakeholders.root),
    element: <PeopleBusinessUnitPage />,
  },
  {
    path: PATH_ROOT.activity.root,
    element: <ActivitiesPage />,
  },
  {
    path: `/portal-builder/:teamSlug/:pageSlug`,
    element: <OrganizationPortalBuilderPage />,
  },
  {
    path: PATH_ROOT.leadGenCopilot.root,
    element: <LeadGenGPTPage />,
  },
  {
    path: PATH_NAME.sourcings,
    element: <DeprecatedRoute newPath={PATH_ROOT.sourcings.list} />,
  },
  {
    path: `${PATH_NAME.requests}/:requestId`,
    element: <InboxDetailsPage />,
  },
  {
    path: pathForRoutes(PATH_ROOT.projectLeads._details(':projectId')),
    element: <ProjectLeadDetailsPage />,
  },
  { path: PATH_ROOT.root, element: <Home /> },

  {
    path: PATH_NAME.scout,
    element: (
      <ScoutStartupsErrorBoundary>
        <ScoutStartupsPage />
      </ScoutStartupsErrorBoundary>
    ),
  },
  {
    path: PATH_NAME.lists,
    element: <LibraryLayout />,
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.root),
    element: (
      <StartupsLayout currentTab='all'>
        <StartupsPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.all),
    element: (
      <StartupsLayout currentTab='all'>
        <StartupsPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.inbox),
    element: (
      <StartupsLayout currentTab='inbox'>
        <PitchInboxPage />
      </StartupsLayout>
    ),
  },
  {
    path: pathForRoutes(PATH_ROOT.startups.requests),
    element: (
      <StartupsLayout currentTab='requests'>
        <OutboundRequestsPage />
      </StartupsLayout>
    ),
  },
  {
    path: '/scoping',
    element: <DeprecatedRoute newPath={PATH_ROOT.projectLeads.list} />,
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}`,
    element: <ListDetailsPage />,
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}/${PATH_NAME.category}/:${PATH_PARAMS.categoryId}`,
    element: <CategoryDetailsPage isDiscoveryPage={false} />,
  },
  {
    path: `/${PATH_NAME.discovery}/:${PATH_PARAMS.startupListId}/${PATH_NAME.category}/:${PATH_PARAMS.categoryId}`,
    element: <CategoryDetailsPage isDiscoveryPage />,
  },
  {
    path: `/${PATH_NAME.discovery}/:${PATH_PARAMS.startupListId}`,
    element: <ListDetailsPage />,
  },
  {
    path: `/${PATH_NAME.lists}/:${PATH_PARAMS.startupListId}/${PATH_NAME.orderSourcing}`,
    element: <SourcingOrderPage />,
  },
  {
    path: `${PATH_NAME.pocs}/:projectId`,
    element: <ProjectDetailsPage scope='pocs' />,
  },
  {
    path: `${PATH_NAME.adoptions}/:projectId`,
    element: <ProjectDetailsPage scope='adoptions' />,
  },
  {
    path: PATH_NAME.orderSourcing,
    element: <SourcingOrderPage />,
  },

  {
    path: PATH_NAME.pocs,
    element: <PoCsAndAdoptionsPage isPoC scope='projectPoCFilters' />,
  },
  {
    path: PATH_NAME.adoptions,
    element: (
      <PoCsAndAdoptionsPage isPoC={false} scope='projectAdoptionsFilters' />
    ),
  },
  {
    path: PATH_NAME.analytics,
    element: <AnalyticsPage />,
  },
  {
    path: PATH_NAME.settings,
    element: <SettingsLayoutPage />,
    children: [
      {
        path: PATH_NAME.organization,
        element: <OrganizationSettingsPage />,
      },
      {
        path: PATH_NAME.users,
        element: <UsersPage />,
      },
      {
        path: PATH_NAME.teams,
        element: <TeamsPage />,
      },
    ],
  },
];

export { INTERNAL_ROUTES };
