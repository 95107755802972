import { captureException } from '@sentry/react';
import {
  GetThreadsDocument,
  GetThreadsQuery,
  useCreateThreadMutation,
  useGetPersonDetailsLazyQuery,
  useGetPersonInnovationThemesLazyQuery,
} from 'apollo/generated/sdkShared';
import { compact } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export const useStartStakeholderInspirationConversation = ({
  beforeNavigate,
}: {
  beforeNavigate?: () => void;
} = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createThread, { loading }] = useCreateThreadMutation();
  const navigate = useNavigate();
  const { generateInitialMessageForStakeholder } =
    useGenerateInitialMessageForStakeholder();

  const startConversation = async ({
    title,
    stakeholderId,
  }: {
    title: string | null;
    stakeholderId: number | null;
  }) => {
    try {
      let initialMessage: string;
      let topic: string;

      if (stakeholderId) {
        const {
          title: generatedTitle,
          initialMessage: generatedInitialMessage,
        } = await generateInitialMessageForStakeholder({
          stakeholderId,
          title,
        });

        topic = generatedTitle;
        initialMessage = generatedInitialMessage;
      } else if (title) {
        topic = title;
        initialMessage = `${buildTopicBasedOnTitle(title)} <br>Can you recommend me 5 trending topics that may be of interest to them?`;
      } else {
        throw new Error('Title or stakeholderId must be provided');
      }

      const { data } = await createThread({
        variables: {
          title: topic,
          targetPersonId: stakeholderId,
          initialMessage,
        },
        update(cache, { data }) {
          const thread = data?.createThread?.thread;
          if (!thread) return;

          const existingThreads = cache.readQuery<GetThreadsQuery>({
            query: GetThreadsDocument,
          }) || { threads: [] };

          cache.writeQuery({
            query: GetThreadsDocument,
            data: {
              threads: [...existingThreads.threads, thread],
            },
          });
        },
      });

      const newThreadId = data?.createThread?.thread?.id;
      if (!newThreadId) {
        enqueueSnackbar('Error starting conversation', { variant: 'error' });
        return;
      }

      beforeNavigate?.();

      navigate(`${PATH_ROOT.leadGenCopilot.root}?tab=${newThreadId}`);
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Error starting conversation', { variant: 'error' });
    }
  };

  return { startConversation, startingConversation: loading };
};

const useGenerateInitialMessageForStakeholder = () => {
  const [getPersonData] = useGetPersonDetailsLazyQuery();
  const [getPersonInterests] = useGetPersonInnovationThemesLazyQuery();
  const generateInitialMessageForStakeholder = useCallback(
    async ({
      stakeholderId,
      title,
    }: {
      stakeholderId: number;
      title: string | null;
    }) => {
      const { data: personData } = await getPersonData({
        variables: { id: stakeholderId },
      });
      const person = personData?.people_by_pk;
      if (!person) {
        throw new Error('Person not found');
      }

      const { data: personInterestsData } = await getPersonInterests({
        variables: { personId: stakeholderId },
      });
      const personInterestsOptions =
        personInterestsData?.enum_table_innovation_themes || [];
      const personInterests = compact(
        (personInterestsData?.people_by_pk?.innovation_themes || []).map(
          theme =>
            personInterestsOptions.find(t => t.value === theme.innovation_theme)
              ?.name,
        ),
      );
      const personTags = (person?.tags || []).map(tag => tag.tag.name);

      const hasContext =
        person.department || personInterests.length || personTags.length;

      const topic = title
        ? buildTopicBasedOnTitle(title)
        : 'I want to target a stakeholder based on their interests and needs.';

      return {
        title: title || `Inspiration email: ${person.full_name}`,
        initialMessage:
          `${topic} ${hasContext ? 'Here is what I know about them:<br>' : '<br>'}` +
          `${person.department ? `- Department: ${person.department}<br>` : ''}` +
          `${personInterests.length ? `- Interests: ${personInterests.join(', ')}<br>` : ''}` +
          `${personTags.length ? `- Other interests: ${personTags.join(', ')}<br>` : ''}` +
          '<br>Can you recommend me 5 trending topics that may be of interest to them?'.trim(),
      };
    },
    [getPersonData, getPersonInterests],
  );

  return { generateInitialMessageForStakeholder };
};

const buildTopicBasedOnTitle = (title: string) => {
  return `I want to target a stakeholder by presenting challenges and innovative solutions in the area of: "${title}".`;
};
