import { Avatar, SxProps, Theme, alpha } from '@mui/material';
import * as colors from '@mui/material/colors';

const muiColorArray = [
  colors.red[500],
  colors.pink[500],
  colors.purple[500],
  colors.deepPurple[500],
  colors.indigo[500],
  colors.blue[500],
  colors.lightBlue[500],
  colors.cyan[500],
  colors.teal[500],
  colors.green[500],
  colors.lightGreen[500],
  colors.lime[500],
  colors.yellow[600], // Using a darker shade for lighter colors
  colors.amber[500],
  colors.orange[500],
  colors.deepOrange[500],
  colors.brown[500],
  colors.grey[500],
  colors.blueGrey[500],
];

function stringToColor(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % muiColorArray.length;
  return muiColorArray[index];
}

function stringAvatar(name: string) {
  const splitName = name.split(' ');
  const firstLetter = splitName[0] ? splitName[0][0] : 'A';
  const lastLetter = splitName[1] ? splitName[1][0] : '';
  return {
    sx: {
      bgcolor: alpha(stringToColor(name), 0.3),
      color: stringToColor(name),
      marginRight: 1,
      fontWeight: 500,
    },
    children: `${firstLetter}${lastLetter}`.toUpperCase(),
  };
}

const BaseInitialsAvatar = ({
  full_name,
  sx,
  src,
}: {
  full_name: string;
  sx?: SxProps<Theme>;
  src?: string;
}) => {
  const avatarSx = stringAvatar(full_name).sx;
  return (
    <Avatar
      {...stringAvatar(full_name)}
      {...(src ? { src, children: null } : {})}
      sx={{
        ...avatarSx,
        ...sx,
      }}
    />
  );
};

export default BaseInitialsAvatar;
