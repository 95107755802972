import { ArrowBackIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  GetSourcingOrdersDocument,
  useGetSourcingOrderByListIdQuery,
  useSubmitDraftSourcingOrderMutation,
} from 'apollo/generated/sdkInnovationManager';
import { DistanceFromDate } from 'components/shared/DistanceFromDate';
import {
  ORGANIZATION_QUERY_FOR_REFRESH,
  useCurrentOrganizationFromContext,
} from 'contexts/CurrentOrganizationContext';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import Page from '../../components/Page';

import { useApolloClient } from '@apollo/client';
import {
  GetFilesByProblemScopeIdDocument,
  GetProblemScopeByStartupListIdDocument,
  useCreateOrUpdateProblemScopeMutation,
  useGetProblemScopeByStartupListIdQuery,
  useGetStartupListForScopeQuery,
} from 'apollo/generated/sdkShared';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { SEARCH_PARAMS } from 'config';
import { Form, FormikProvider, useFormik } from 'formik';
import useAddStartupToList from 'hooks/useAddStartupToList';
import useInterval from 'hooks/useInterval';
import _, { differenceBy } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  captureAnalyticsEvent,
  identifyAnonymousUser,
} from 'plugins/Analytics';
import { useSearchParams } from 'react-router-dom';
import { KnownStartup } from '../../@types/startupList';
import RequestFormFields, { RequestFieldsSkeleton } from './RequestFormFields';
import { SourcingOrderState, sourcingOrderModel } from './sourcingOrderModel';
import { useUploadProblemScopeFile } from './useScopeFiles';
import { validationSchema } from './validationSchema';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: { padding: spacing(3, 2) },
  stepper: { marginY: spacing(6) },
  card: { marginY: spacing(2) },
}));

export type RenderFooterProps = {
  isLoading?: boolean;
  errorMessage?: string;
};

type UploadedFileId = { id: number | undefined } | undefined;

export default function SourcingOrderPage({
  renderWithinList,
  closeOrderModal,
}: {
  renderWithinList?: boolean;
  closeOrderModal?: () => void;
}) {
  const [searchParams] = useSearchParams();
  const { startupListId, startupListPublicUUID } = useParams<{
    startupListId: string;
    startupListPublicUUID: string;
  }>();

  const listIdFromParams = searchParams.get(SEARCH_PARAMS.listId)
    ? Number(searchParams.get(SEARCH_PARAMS.listId))
    : Number(startupListId);

  const { data: startupListData } = useGetStartupListForScopeQuery({
    variables: { publicUUID: startupListPublicUUID! },
    skip: !startupListPublicUUID,
  });

  const listId = listIdFromParams || startupListData?.startup_lists[0]?.id;
  const categoryId =
    startupListData?.startup_lists[0]?.project_categories[0]?.id;

  if (!listId) {
    throw new Error('Missing list ID');
  }

  const {
    data: sourcingOrderData,
    loading: sourcingOrderDataLoading,
    error: sourcingOrderDataError,
  } = useGetSourcingOrderByListIdQuery({
    variables: { id: listId },
    fetchPolicy: 'network-only',
  });

  const { data: problemScopeData, loading: problemScopeLoading } =
    useGetProblemScopeByStartupListIdQuery({
      variables: {
        startupListId: listId,
      },
      skip: listId === undefined,
    });

  const order = sourcingOrderData?.sourcing_orders[0];
  const problemScope = problemScopeData?.problem_scopes[0];

  if (!listId) {
    throw new Error('Missing list ID');
  }

  if (
    sourcingOrderDataLoading ||
    problemScopeLoading ||
    sourcingOrderDataError
  ) {
    return <RequestFieldsSkeleton />;
  }

  return (
    <RequestForm
      renderWithinList={renderWithinList}
      preloadedState={sourcingOrderModel(listId, order, problemScope)}
      closeOrderModal={closeOrderModal}
      isByStakeholder={!!startupListPublicUUID}
      categoryId={categoryId}
    />
  );
}

const RequestForm = ({
  preloadedState,
  renderWithinList,
  closeOrderModal,
  isByStakeholder = false,
  categoryId,
}: {
  preloadedState: SourcingOrderState;
  renderWithinList?: boolean;
  closeOrderModal?: () => void;
  isByStakeholder?: boolean;
  categoryId?: number;
}) => {
  const classes = useStyles();
  const _navigate = useNavigate();
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [state, setState] = useState<SourcingOrderState>(preloadedState);

  const location = useLocation();
  const locationState =
    (location.state as {
      backToPage?: string;
    }) || {};

  const navigateBack = useCallback(() => {
    if (locationState.backToPage) {
      _navigate(locationState.backToPage);
    } else {
      _navigate(-1);
    }
  }, [locationState.backToPage, _navigate]);

  const currentOrganization = useCurrentOrganizationFromContext();
  const [saving, setSaving] = useState<boolean>(false);
  const schema = validationSchema();
  const currentValidationSchema = schema['Briefing Form Validation'];
  const [createOrUpdateProblemScope] = useCreateOrUpdateProblemScopeMutation();
  const [submitDraftSourcingOrder] = useSubmitDraftSourcingOrderMutation();
  const { addStartupToList } = useAddStartupToList();
  const uploadProblemScopeFile = useUploadProblemScopeFile();
  const [isUploadingScopeFile, setIsUploadingScopeFile] = useState(false);
  const uploadedFileIds: UploadedFileId[] = [];

  const apolloClient = useApolloClient();

  const formik = useFormik<SourcingOrderState>({
    enableReinitialize: true,
    initialValues: state,
    validationSchema: currentValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await submitDraftSourcingOrder({
        variables: {
          object: {
            sourcing_order_id: values.id,
          },
        },
        refetchQueries: [
          { query: ORGANIZATION_QUERY_FOR_REFRESH },
          // TODO: refactor this to use the cache instead of refetching
          // We have to refetch here because the action does not return any updated data (yet)
          { query: GetSourcingOrdersDocument },
        ],
      });
      setSubmitting(false);
      enqueueSnackbar('Your sourcing has been ordered', { variant: 'success' });
      navigateBack();
    },
  });
  const sourcingOrderStatus = formik.values.status;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (state?.stakeholder) {
      identifyAnonymousUser(
        {
          ...state.stakeholder,
          name: state.stakeholder.full_name || 'Unknown name',
        },
        {
          subdomain: currentOrganization.subdomain,
          id: currentOrganization.id,
        },
      );
    }
  }, [
    state?.stakeholder,
    currentOrganization.subdomain,
    currentOrganization.id,
  ]);

  const isSameAsInitialValues = _.isEqual(formik.initialValues, formik.values);

  const handleSave = async (state: SourcingOrderState) => {
    setSaving(true);
    setState(state);

    const keysToPick: (keyof KnownStartup)[] = [
      'domain',
      'name',
      'product',
      'logo',
      'excludeWhenBenchmarking',
    ];

    const scopeKnownStartups: KnownStartup[] = state.known_startups.map(
      (startup: KnownStartup) =>
        Object.fromEntries(
          keysToPick
            .map(key => [key, startup[key]])
            .filter(([, value]) => value !== undefined),
        ),
    );

    const createdScope = await createOrUpdateProblemScope({
      variables: {
        object: {
          startup_list_id: state.startupListId,
          additional_info: state.additional_info,
          desired_solution: state.desired_solution,
          must_have_features: state.must_have_features,
          nice_to_have_features: state.nice_to_have_features,
          problem: state.problem,
          known_startups: scopeKnownStartups,
        },
      },
    });

    const problemScopeId = createdScope.data?.insert_problem_scopes_one?.id;

    if (!problemScopeId) {
      enqueueSnackbar('Failed to create or update problem scope', {
        variant: 'error',
      });
      setSaving(false);
      return;
    }

    if (state.known_startups.length > 0 && isByStakeholder) {
      const startupsToAddToList = differenceBy(
        state.known_startups as KnownStartup[],
        (formik.initialValues?.known_startups as KnownStartup[]) || [],
        'domain',
      );
      for (const startup of startupsToAddToList) {
        await addStartupToList({
          selectedStartup: {
            ...startup,
          },
          listId: state.startupListId,
          categoryId: categoryId,
        });
      }
    }

    if (state.scope_files.length > 0) {
      const filesToUpload = differenceBy(
        state.scope_files,
        formik.initialValues.scope_files,
        'name',
      );

      if (filesToUpload.length > 0) {
        setIsUploadingScopeFile(true);
        await Promise.all(
          filesToUpload.map(file =>
            uploadProblemScopeFile(file, problemScopeId),
          ),
        )
          .then(fileIds => {
            if (fileIds && fileIds.length > 0) {
              uploadedFileIds.push(...fileIds);
            }
          })
          .catch(() => {
            enqueueSnackbar('Failed to upload file', { variant: 'error' });
            setIsUploadingScopeFile(false);
          });

        captureAnalyticsEvent('File added to scope', {
          listId: state.startupListId,
        });
      }
    }
    if (!state.problemScopeId && problemScopeId) {
      setState(prev => ({
        ...prev,
        problemScopeId,
      }));

      apolloClient.writeQuery({
        query: GetProblemScopeByStartupListIdDocument,
        variables: { startupListId: state.startupListId },
        data: {
          problem_scopes: [
            {
              ...createdScope.data?.insert_problem_scopes_one,
              files: uploadedFileIds,
            },
          ],
        },
      });
    }

    if (uploadedFileIds.length > 0) {
      const observableQuery = apolloClient.watchQuery({
        query: GetFilesByProblemScopeIdDocument,
        variables: { problemScopeId: state.problemScopeId },
      });

      observableQuery.refetch();
      setIsUploadingScopeFile(false);
    }
    await logStartupListActivity({
      logs: [
        {
          action: 'created',
          entityIds: problemScopeId,
          entityType: 'problem_scopes',
        },
        ...(uploadedFileIds.length > 0
          ? [
              {
                action: 'created',
                entityIds: uploadedFileIds
                  .map(file => file?.id)
                  .filter((id): id is number => id !== undefined),
                entityType: 'problem_scope_files',
              },
            ]
          : []),
      ],
      startupListId: state.startupListId,
    });
    setSaving(false);
  };

  useInterval(
    () => {
      const isOkayToSave = !isSameAsInitialValues;

      if (isOkayToSave) {
        handleSave(formik.values);
      }
    },
    2 * 1000,
    [formik.values],
  );

  if (!currentOrganization) return <RequestFieldsSkeleton />;

  const renderFooter = (props: RenderFooterProps) => {
    return (
      <Stack
        direction='row'
        justifyContent='flex-end'
        marginLeft='auto'
        gap={1}
      >
        <LoadingButton
          variant={sourcingOrderStatus === 'draft' ? 'outlined' : 'contained'}
          onClick={() => {
            handleSave(formik.values);
            closeOrderModal ? closeOrderModal() : navigateBack();
          }}
          disabled={isUploadingScopeFile}
          loading={isUploadingScopeFile || saving || formik.isSubmitting}
        >
          {isUploadingScopeFile
            ? 'Uploading...'
            : !state.problemScopeId
              ? 'Save'
              : sourcingOrderStatus === 'draft'
                ? 'Save Draft'
                : 'Update'}
        </LoadingButton>
        {sourcingOrderStatus === 'draft' && (
          // eslint-disable-next-line
          <Tooltip title={props.errorMessage || ''} placement='top'>
            <span>
              <LoadingButton
                variant='contained'
                type='submit'
                disabled={
                  isUploadingScopeFile ||
                  formik.isSubmitting ||
                  !formik.isValid ||
                  saving
                }
                loading={isUploadingScopeFile || saving || formik.isSubmitting}
              >
                Submit
              </LoadingButton>
            </span>
          </Tooltip>
        )}
      </Stack>
    );
  };

  if (renderWithinList) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <RequestFormFields
            state={state}
            renderFooter={renderFooter}
            shownInModal
          />
        </Form>
      </FormikProvider>
    );
  }

  return (
    <Page
      title='Request form | GlassDollar'
      trackingTitle='Request Form'
      className={classes.root}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography
          align='left'
          variant='h4'
          style={{ padding: 0, margin: 0, cursor: 'pointer' }}
          onClick={navigateBack}
        >
          <ArrowBackIos
            style={{
              position: 'relative',
              top: '-3px',
              width: '10px',
              fontSize: 'bold',
              verticalAlign: 'middle',
              marginRight: '5px',
            }}
          />
          Order a sourcing
        </Typography>
        {formik.values.updated_at && (
          // TODO: Check why this does not update on save
          <DistanceFromDate date={formik.values.updated_at} />
        )}
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate>
          <RequestFormFields state={state} renderFooter={renderFooter} />
        </Form>
      </FormikProvider>
    </Page>
  );
};
