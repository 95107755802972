import { Check, KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GetStartupListPermissionAclQuery,
  useDeleteStartupListAclMutation,
  useGetBusinessUnitPeopleCountQuery,
  useGetBusinessUnitPeopleQuery,
  useGetStartupListPermissionAclQuery,
} from 'apollo/generated/sdkShared';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { smallTextStyles } from 'components/base/typography/constants';
import { EmailField } from 'components/shared/EmailField';
import TeamAvatar from 'components/teams/TeamAvatar';
import { useGetStartupListTeamAvatarUrl } from 'components/teams/useGetStartupListTeamAvatarUrl';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useMemo, useState } from 'react';
import { StartupListForShare } from '../../dashboard/startupList/details/share/ShareStartupList';
import { usePersonContext } from 'contexts/PersonContext';
import { useSnackbar } from 'notistack';

const AclRow = ({
  person,
  startup_list,
  startup_list_id,
  refetch,
  isAuthor,
}: GetStartupListPermissionAclQuery['startup_lists_acl'][number] & {
  refetch: ReturnType<typeof useGetStartupListPermissionAclQuery>['refetch'];
  isAuthor?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { person: loggedInPerson } = usePersonContext();
  const { spacing } = useTheme();
  const [deleteAcl] = useDeleteStartupListAclMutation();
  const fullName = person?.full_name ?? 'Unknown';
  const department = person?.department ?? '';
  const collaborators = useMemo(() => {
    const _collaborators = startup_list?.collaborators ?? [];

    if (isAuthor) {
      return [
        ..._collaborators,
        { role: 'Author' } as (typeof _collaborators)[number],
      ];
    }

    return _collaborators;
  }, [isAuthor, startup_list?.collaborators]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const buttonId = `list-permissions-button-${startup_list_id}`;
  const menuId = `list-permissions-menu-${startup_list_id}`;
  const shouldShowAccessMenu = !isAuthor && loggedInPerson?.id !== person?.id;

  return (
    <Stack direction='row' alignItems='center'>
      <BaseInitialsAvatar full_name={fullName} />
      <Stack>
        <Typography>
          {fullName} {department ? `(${department})` : ''}
        </Typography>
        <Stack direction='row' gap={1} alignItems='center'>
          {collaborators.length > 0 &&
            collaborators.map(collaborator => (
              <Typography
                key={collaborator.id}
                sx={{ ...smallTextStyles, color: 'grey.500' }}
              >
                {collaborator.role}
              </Typography>
            ))}
          {collaborators.length > 0 && (
            <Divider sx={{ height: spacing(1.5) }} orientation='vertical' />
          )}
          {person?.email && <EmailField value={person?.email} />}
        </Stack>
      </Stack>
      {shouldShowAccessMenu && (
        <Stack sx={{ marginLeft: 'auto' }}>
          <Button
            sx={{ ...smallTextStyles, color: 'grey.500', whiteSpace: 'nowrap' }}
            endIcon={<KeyboardArrowDown />}
            id={buttonId}
            onClick={e => {
              setAnchorEl(e.currentTarget);
            }}
            aria-controls={open ? menuId : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            Full access
          </Button>
        </Stack>
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': buttonId }}
      >
        <MenuItem onClick={() => {}}>
          <Stack direction='row' alignItems='center'>
            <Stack>
              <Stack direction='row' alignItems='center' width='100%'>
                <Typography>Full access</Typography>
              </Stack>
              <Typography variant='caption' color='grey.500'>
                Can view and share with others
              </Typography>
            </Stack>
            <Check sx={{ marginLeft: 'auto' }} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await deleteAcl({
              variables: { startupListId: startup_list_id },
            });
            enqueueSnackbar('Removed', { variant: 'success' });
            await refetch();

            handleClose();
          }}
        >
          <Typography>Remove</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

const StartupListAcl = ({
  currentStartupList,
  aclData,
  refetch,
}: {
  currentStartupList: StartupListForShare;
  aclData: GetStartupListPermissionAclQuery['startup_lists_acl'];
  refetch: ReturnType<typeof useGetStartupListPermissionAclQuery>['refetch'];
}) => {
  const { spacing } = useTheme();
  const { name, logo_url } = useCurrentOrganizationFromContext();
  const teamLogoUrl = useGetStartupListTeamAvatarUrl({
    startupListId: currentStartupList.id,
  });
  const { data: allOrgPeople } = useGetBusinessUnitPeopleCountQuery({
    variables: { filter: {} },
  });
  const { data: authorData } = useGetBusinessUnitPeopleQuery({
    variables: {
      filter: { user: { id: { _eq: currentStartupList.created_by_user_id } } },
    },
    skip: !currentStartupList.created_by_user_id,
  });

  const author = authorData?.people[0];

  // TODO: Skeleton loaders
  return (
    <Stack gap={1}>
      <Typography variant='h6' sx={{ marginBottom: 1 }}>
        People with access
      </Typography>
      {currentStartupList.visibility === 'organization' && (
        <Stack direction='row' alignItems='center'>
          <BaseInitialsAvatar full_name={name} src={logo_url} />
          <Stack>
            <Typography>Everyone at {name}</Typography>
            <Typography variant='caption' color='grey.500'>
              {allOrgPeople?.people.length} members
            </Typography>
          </Stack>
        </Stack>
      )}
      {currentStartupList.visibility === 'team' && currentStartupList.team && (
        <Stack direction='row' alignItems='center' gap={1}>
          <TeamAvatar
            sx={{
              width: `${spacing(5)} !important`,
              height: `${spacing(5)} !important`,
            }}
            alt=''
            src={teamLogoUrl || ''}
          />
          <Stack>
            <Typography>{currentStartupList.team.name}</Typography>
            <Typography variant='caption' color='grey.500'>
              {currentStartupList.team.people?.length} members
            </Typography>
          </Stack>
        </Stack>
      )}
      {author && (
        <AclRow
          key={author.id}
          refetch={refetch}
          person={author}
          id={author.id}
          startup_list_id={currentStartupList.id}
          isAuthor
        />
      )}
      {aclData.map(acl => (
        <AclRow key={acl.id} {...acl} refetch={refetch} />
      ))}
    </Stack>
  );
};

export default StartupListAcl;
