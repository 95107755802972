import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';

import { FeaturesUpdateBanner } from 'components/SystemMessages/FeaturesUpdateBanner';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { FullscreenContext } from 'contexts/FullscreenContext';
import DashboardNavbar from './DashboardNavbar';
import MenuSidebar from './MenuSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
export const UPDATES_BANNER_HEIGHT = 50;

type UpdateReleasedProps = {
  isNewUpdateReleased?: boolean;
};

const RootStyle = styled('div')<UpdateReleasedProps>(
  ({ isNewUpdateReleased }) => ({
    display: 'flex',
    minHeight: '100%',
    width: '100vw',
    ...(isNewUpdateReleased && {
      height: `calc(100vh - ${UPDATES_BANNER_HEIGHT}px)`,
    }),
  }),
);

const MainStyle = styled('div')<UpdateReleasedProps>(
  ({ theme, isNewUpdateReleased }) => ({
    flexGrow: 1,
    overflow: 'clip',
    minHeight: '100%',
    paddingTop:
      APP_BAR_MOBILE + 24 + (isNewUpdateReleased ? UPDATES_BANNER_HEIGHT : 0),
    [theme.breakpoints.up('lg')]: {
      paddingTop:
        APP_BAR_DESKTOP +
        24 +
        (isNewUpdateReleased ? UPDATES_BANNER_HEIGHT : 0),
    },
  }),
);

// ----------------------------------------------------------------------

function DashboardLayout() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { isFeaturesUpdatesBannerVisible: isNewUpdateReleased } =
    useFeaturesUpdatesBanner();
  const { isFullscreenView } = useContext(FullscreenContext);

  return (
    <RootStyle
      sx={{
        [theme.breakpoints.down('lg')]: {
          paddingLeft: '9vw',
        },
      }}
    >
      {isNewUpdateReleased && <FeaturesUpdateBanner />}
      {!isFullscreenView && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <MenuSidebar
            theme={theme}
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        </>
      )}
      <MainStyle
        isNewUpdateReleased={isNewUpdateReleased}
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

export default DashboardLayout;
