import LoadingScreen from 'components/LoadingScreen';
import { useGetKpisMetricsAndDeliverables } from 'hooks/useGetKpisMetricsAndDeliverables';
import {
  ProjectTimeline,
  StartupTimeline,
  useGetProjectAndStartupTimelines,
} from 'hooks/useGetProjectAndStartupTimelines';
import {
  CategorizedStartupForProjectAndStartups,
  useGetProjectAndStartups,
} from 'hooks/useGetProjectAndStartups';

import RequestProjectAccessDialog from 'components/permissions/projects/RequestProjectAccessDialog';
import useGrantProjectAccess from 'components/permissions/projects/useGrantProjectAccess';
import Page500 from 'pages/Page500';
import { AssessmentCategorizedSupplier } from 'pages/dashboard/scoping/AssessmentTab';
import {
  Deliverable,
  ImpactMetric,
  KPI,
} from 'pages/dashboard/scoping/hooks/useTestingFormik';
import { createContext, useContext, useMemo } from 'react';
import { matchPath } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { SharedProjectCategory } from '../@types/shared';
import { ProjectForDetail } from '../@types/startupList';

export type TabType =
  | 'scoping'
  | 'sourcing'
  | 'selected_suppliers'
  | 'assessment'
  | 'purchasing'
  | 'testing'
  | 'documents';

type ProjectsPath = Pick<typeof PATH_ROOT, 'projects'>['projects'];

export type PageScope = keyof Pick<
  ProjectsPath,
  'scoping' | 'pocs' | 'adoptions'
>;

export type ProjectScope = ProjectForDetail & {
  business_impact_estimate_degree_of_accuracy?: number | null;
  difficulty?: number | null;
  poc_cost?: number | null;
  poc_description?: string | null;
  impact_description?: string | null;
  poc_outcome?: string | null;
  poc_purchasing_lead_email?: string | null;
  deliverables_and_milestones?: Deliverable[];
  kpis?: KPI[];
  impact_metrics?: ImpactMetric[];
};
// ----------------------------------------------------------------------
type StartupListDetailPageContextType = {
  isPublicPage: boolean;
  project: ProjectScope;
  /**
   * @deprecated contains categories of all startup lists, use `startupList.project_categories` instead when possible
   */
  categories: SharedProjectCategory[];
  categorizedStartups: CategorizedStartupForProjectAndStartups[];
  selectedStartup?: AssessmentCategorizedSupplier;
  shortlistedStartups: AssessmentCategorizedSupplier[];
  startupsInMatrix: AssessmentCategorizedSupplier[];
  startupsInMatrixAndNotShortlisted: AssessmentCategorizedSupplier[];
  projectTimeline: ProjectTimeline;
  startupTimeline: StartupTimeline;
  kpis: KPI[];
  deliverables: Deliverable[];
  impactMetrics: ImpactMetric[];
  scope: 'pocs' | 'scoping' | 'adoptions';
  refetch: () => void;
  isLeadsDetails: boolean;
  isPocDetails: boolean;
  isAdoptionDetails: boolean;
  inaccessibleLists: {
    id: number;
    startup_list_id: number;
  }[];
};

export const StartupListDetailPageContext =
  createContext<StartupListDetailPageContextType>(
    // TODO: replace this with allowing `null` default state and hiding it behind a custom hook
    {} as StartupListDetailPageContextType,
  );

export type Props = { scope: PageScope };

export const ProjectScopeContext = ({
  projectId,
  publicUUID,
  scope,
  children,
}: {
  projectId?: number;
  publicUUID?: string;
  scope: 'pocs' | 'scoping' | 'adoptions';
  children: React.ReactNode;
}) => {
  const isPublicPage = !!publicUUID;

  const {
    error,
    loading,
    categorizedStartups,
    shortlistedStartups,
    startupsInMatrixAndNotShortlisted,
    categories,
    inaccessibleLists,
    project,
    refetch,
  } = useGetProjectAndStartups({
    projectId: projectId!,
    publicUuid: publicUUID,
  });

  const {
    pocDeliverables,
    pocKpis,
    pocImpactMetrics,
    loading: metricsLoading,
  } = useGetKpisMetricsAndDeliverables({
    // eslint-disable-next-line
    projectId: project?.id!,
    startupId: shortlistedStartups.find(ss => ss.is_selected)?.startup.id,
  });

  const { isOpen, GrantAccessDialog } = useGrantProjectAccess(
    project?.title || '',
    project?.id || null,
  );

  const { startupTimeline, projectTimeline } = useGetProjectAndStartupTimelines(
    {
      // eslint-disable-next-line
      projectId: project?.id!,
    },
  );

  const startupsInMatrix = useMemo(
    () =>
      categorizedStartups.filter(
        categorizedStartup =>
          categorizedStartup.is_in_matrix && !categorizedStartup.is_archived,
      ) || [],
    [categorizedStartups],
  );

  const selectedStartup = useMemo(
    () => shortlistedStartups?.find(ss => ss.is_selected),
    [shortlistedStartups],
  );
  const isLeadsDetails = !!matchPath(
    PATH_ROOT.projectLeads._details(':projectId'),
    location.pathname,
  );

  const isPocDetails = !!matchPath(
    PATH_ROOT.projects._pocDetails(':projectId'),
    location.pathname,
  );

  const isAdoptionDetails = !!matchPath(
    PATH_ROOT.projects._adoptionDetails(':projectId'),
    location.pathname,
  );
  if (error) return <Page500 embedded />;

  if (metricsLoading || loading) return <LoadingScreen />;
  if (!project) {
    console.log({ project });

    return (
      <RequestProjectAccessDialog
        projectId={projectId}
        projectPublicUUID={publicUUID}
      />
    );
  }

  return (
    <StartupListDetailPageContext.Provider
      value={{
        categorizedStartups,
        isPublicPage,
        categories,
        selectedStartup,
        startupsInMatrixAndNotShortlisted,
        shortlistedStartups: shortlistedStartups,
        startupsInMatrix,
        project,
        projectTimeline,
        startupTimeline,
        deliverables: pocDeliverables,
        kpis: pocKpis,
        impactMetrics: pocImpactMetrics,
        inaccessibleLists,
        scope,
        isLeadsDetails,
        isPocDetails,
        isAdoptionDetails,
        refetch,
      }}
    >
      {children}
      {isOpen && GrantAccessDialog}
    </StartupListDetailPageContext.Provider>
  );
};

export const useProjectScopeContext = () => {
  const state = useContext(StartupListDetailPageContext);

  if (!state) {
    throw new Error(
      `using useProjectScopeContext without the appropriate provider`,
    );
  }

  return state;
};
